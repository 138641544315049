import React from 'react'
import Banner from '../Banner'
import Blogs from './Blogs'
import { Helmet } from 'react-helmet';


const Blog = () => {
    return (
        <>
            <Helmet>
                <title>Blog - Harsh Saini</title>
                <meta name="description" content="Read the latest articles and insights from Harsh Saini on web development and technology." />
                <meta name="keywords" content="Blog, Harsh Saini, Web Development, Technology" />

                {/* Open Graph */}
                <meta property="og:title" content="Blog - Harsh Saini" />
                <meta property="og:description" content="Read the latest articles and insights from Harsh Saini on web development and technology." />
                <meta property="og:image" content="https://iamharsh.org/path-to-your-og-image.png" />
                <meta property="og:url" content="https://iamharsh.org/blog" />
                <meta property="og:type" content="website" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Blog - Harsh Saini" />
                <meta name="twitter:description" content="Read the latest articles and insights from Harsh Saini on web development and technology." />
                <meta name="twitter:image" content="https://iamharsh.org/path-to-your-twitter-image.png" />

                {/* Structured Data */}
               
            </Helmet>
            <main class="site-content" id="content">
                <Banner heading="Blog" />
                <Blogs />
            </main>
        </>
    )
}

export default Blog