import React from 'react'
import Banner from '../Banner'
import Works from '../home/Works'
import { Helmet } from 'react-helmet';

const Portfolio = () => {
    return (
        <>

            <Helmet>
                <title>Portfolio - Harsh Saini</title>
                <meta name="description" content="View the portfolio of Harsh Saini showcasing various web development projects and case studies." />
                <meta name="keywords" content="Portfolio, Harsh Saini, Web Development Projects" />

                {/* Open Graph */}
                <meta property="og:title" content="Portfolio - Harsh Saini" />
                <meta property="og:description" content="Explore the portfolio of Harsh Saini with a collection of web development projects and case studies." />
                <meta property="og:image" content="https://iamharsh.org/path-to-your-og-image.png" />
                <meta property="og:url" content="https://iamharsh.org/portfolio" />
                <meta property="og:type" content="website" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Portfolio - Harsh Saini" />
                <meta name="twitter:description" content="Explore the portfolio of Harsh Saini with a collection of web development projects and case studies." />
                <meta name="twitter:image" content="https://iamharsh.org/path-to-your-twitter-image.png" />

                {/* Structured Data */}
                
            </Helmet>
            <main class="site-content" id="content">
                <Banner heading="Portfolio" />
                <Works />

            </main>
        </>
    )
}

export default Portfolio